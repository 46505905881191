<template>
  <button id="scroll-up-btn" class="scroll-up-btn" @click="scrollToTop">
    <i class="arrow-up"></i>
  </button>

  <div :class="[theme, 'container']">
    <div class="content">
      <h1>
        <a href="/" class="homepage-link">{{ translatedText.title }}</a>
      </h1>
      <h4>{{ translatedText.subtitle1 }}</h4>
      <h4>{{ translatedText.subtitle2 }}</h4>

      <div v-if="showChangelog" class="changelog-box">
        <p v-html="YouTubeChangelog"></p>
        <button class="close-changelog" @click="closeChangelog">X</button>
      </div>

      <!-- Filter and Settings controls -->
      <div class="rss-filter">
  <button class="filter-toggle" @click="toggleFilters">
    {{ showFilters ? translatedText.hideFilters : translatedText.showFilters }}
  </button>
  <button class="filter-toggle" @click="toggleSettings">
    {{ showSettings ? translatedText.hideSettings : translatedText.settings }}
  </button>


  <!-- Contact Button -->
  <button class="filter-toggle" @click="showContactForm = true">
    {{ translatedText.contact }}
  </button>

  <!-- Sort By Button and Dropdown -->
  <button class="filter-toggle" @click="toggleSortOptions">
  Sort By
</button>
<div v-show="showSortOptions" class="sort-options-dropdown">
  <button class="sort-option" :class="{ selected: selectedSort === 'mostRecent' }" @click="sortBy('mostRecent')">
    Most Recent
  </button>
  <button class="sort-option" :class="{ selected: selectedSort === 'mostViewed' }" @click="sortBy('mostViewed')">
    Most Viewed
  </button>
</div>

<!-- Contact Form Modal -->
<div v-if="showContactForm" class="contact-modal">
  <div class="contact-modal-content">
    <button class="close-modal" @click="showContactForm = false">X</button>
    <h2 v-if="!successMessage">{{ translatedText.contactUs }}</h2>

    <form @submit.prevent="submitForm" v-if="!successMessage">
      <label for="name">Name</label>
      <input type="text" id="name" v-model="name" required :placeholder="translatedText.contactName" />

      <label for="email">Email</label>
      <input type="email" id="email" v-model="email" required :placeholder="translatedText.contactEmail" />

      <label for="message">Message</label>
      <textarea id="message" v-model="message" required :placeholder="translatedText.contactMessage"></textarea>

      <button type="submit" class="submit-btn">{{translatedText.contactSubmit}}</button>
    </form>

    <div v-if="successMessage" class="thank-you-message">
      <h3>{{ successMessage }}</h3>
      <button class="close-modal" @click="showContactForm = false">X</button>
    </div>
  </div>
</div>



        <div v-show="showFilters" class="filter-controls-wrapper">
    <!-- Stack Select and Deselect buttons -->
    <div class="filter-buttons">
      <button class="filter-control" @click="selectAllFilters">{{ translatedText.selectAll }}</button>
      <button class="filter-control" @click="deselectAllFilters">{{ translatedText.deselectAll }}</button>
    </div>
    <div class="rss-tag-container">
      <div
        v-for="source in rssSources"
        :key="source.name"
        class="rss-tag-filter"
        :class="{ selected: selectedFilters.includes(source.name) }"
        :style="{
          backgroundColor: selectedFilters.includes(source.name)
            ? source.color
            : theme === 'dark'
            ? '#151719'
            : '#ddd',
          color: selectedFilters.includes(source.name)
            ? isBrightColor(source.color)
              ? '#000'
              : '#fff'
            : theme === 'dark'
            ? '#fff'
            : '#000',
          fontWeight: 'bold',
        }"
        @click.prevent="toggleFilter(source.name)"
      >
        {{ source.name }}
      </div>
    </div>
  </div>

        <!-- Settings dropdown -->
        <div v-show="showSettings" class="settings-dropdown">

          <!-- Language Selector -->
          <div class="language-selector">
  <div class="custom-select">

  </div>
</div>

          <!-- Light/Dark mode toggle -->
          <div class="theme-toggle">
            <label for="themeToggle">{{ translatedText.themeToggleLabel }}</label>
            <div class="toggle-switch">
              <input type="checkbox" id="themeToggle" v-model="isDarkTheme" @change="toggleTheme" />
              <label for="themeToggle" class="toggle-slider"></label>
            </div>
          </div>
        </div>
      </div>

      <div class="rss-feed">
        <a
  v-for="video in filteredVideos"
  :key="video.videoId || video.id?.videoId"
  class="rss-item"
  @click.prevent="openVideo(video.link)"
>
  <h2>{{ decodeHtml(video.title) }}</h2>
  <div class="image-wrapper">
    <img
      :src="video.image || placeholderImageUrl"
      :alt="video.title"
      class="rss-image"
    />
  </div>

              <!-- Display Views, Likes, Comments -->
              <div class="video-stats">
            <span><strong>Views:</strong> {{ video.views }}</span>
          </div>
  <div class="rss-footer">
    
    <span
    class="rss-tag"
      :style="{
        backgroundColor: getSourceColor(video.sourceName),
        color: isBrightColor(getSourceColor(video.sourceName)) ? '#000' : '#fff'
      }"
    >
    
      <strong>{{ video.sourceName }}</strong>
    </span>
    <span class="separator"> - </span>
    <time>{{ timeSince(new Date(video.pubDate)) }}</time>
  </div>

        </a>
      </div>

<!-- Fullscreen Modal for Video Playback -->
<!-- Fullscreen Modal for Video Playback -->
<div v-if="showModal" class="modal-overlay" @click="closeModal">
  <div class="modal-content" @click.stop>
    <!-- Done button at the top right for desktop -->
    <button class="close-modal-button" @click="closeModal">Go Back</button>
    <div class="video-wrapper">
      <iframe
        :src="`https://www.youtube.com/embed/${activeVideo}?autoplay=1`"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</div>



    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      rssSources: [
  { name: 'Gameranx', channelId: 'UCNvzD7Z-g64bPXxGzaQaa4g', color: '#990E11' },
  { name: 'IGN', channelId: 'UCKy1dAqELo0zrOtPkf0eTMw', color: '#BE1213' },
  { name: 'Last Stand Media', channelId: 'UCt9BGe6i2lJepl-B_zwX6VA', color: '#060A8F' },
  { name: 'Kinda Funny', channelId: 'UCT6QFE3peNry9PdO5uGj96g', color: '#0033D5' },
  { name: 'Skill Up', channelId: 'UCZ7AeeVbyslLM_8-nVy2B8Q', color: '#FF9710' },
  { name: 'Jake Baldino', channelId: 'UC7s6t5KCNwRkb7U_3-E1Tpw', color: '#502111' },
  { name: 'colteastwood', channelId: 'UCyGYHo1qVIeGq3ZLnSDaEcg', color: '#51C8EC' },
  { name: 'Xbox', channelId: 'UCjBp_7RuDBUYbd1LegWEJ8g', color: '#0e7a0d' },
  { name: 'LevelCapGaming', channelId: 'UClMXf2oP5UiW_V4dwHxY0Mg', color: '#83FF00' },
  { name: 'Spawn Wave', channelId: 'UCoIXnB865l9Ex9zs4OIXTdQ', color: '#98228A' },
  { name: 'Easy Allies', channelId: 'UCZrxXp1reP8E353rZsB3jaA', color: '#1CBF95' },
  { name: 'JorRaptor', channelId: 'UCzF5oxzeidHOZzy4KK5nxCQ', color: '#32545E' },
  { name: 'Bellular News', channelId: 'UC3nPaf5MeeDTHA2JN7clidg', color: '#EBF8FC' },
  { name: 'Videogamedunkey', channelId: 'UCsvn_Po0SmunchJYOWpOxMg', color: '#7F8992' },
  { name: 'LtBuzzLitebeer', channelId: 'UCQnZTXSn-Me7D6bmqME3osA', color: '#F88C12' },
  { name: 'Inside Games', channelId: 'UCFHQlasvjQ0JMOHoKOz4c0g', color: '#CB1B27' }
      ],
      rssItems: [],
      searchQuery: '',
      isFetching: false,
      videos: [],
      selectedFilters: [],
      showFilters: false,
      showSettings: false,
      showSortOptions: false,  // To manage the visibility of the sort options
      selectedSort: 'mostRecent',
      showModal: false,
      currentPage: 1,
      itemsPerPage: 16,
      isLoading: false,
      hasMorePosts: true,
      placeholderImageUrl: 'https://i.imgur.com/HlnOAvp.png',
      storageKey: '-YOUTUBE-filters',
      timeLimit: 0,
      showContactForm: false,  // For toggling the contact form popup
      name: "",
      email: "",
      message: "",
      successMessage: "",  // Success message after form submission
      timeLimitStorageKey: '-YOUTUBE-timeLimit',
      theme: 'dark',
      isRefreshed: false,
      isDarkTheme: true,
      themeStorageKey: '-YOUTUBE-theme',
      loadingImages: [],
      showChangelog: false,
      YouTubeChangelog: `
        <h3 style="margin: -10px 0 10px 0; text-decoration: underline;">22nd October</h3>
        <span style="color: green;">Added: Inside Games</span>
        <br>
      `,
      selectedLanguage: 'en',
      translatedText: {},
      languageOptions: {
        en: {
          title: 'YouTube Gaming News',
          subtitle1: 'Stay updated with the latest gaming news from your favourite YouTubers.',
          subtitle2: 'Is your favourite YouTuber not on this list? Hit the contact button to let me know.',
          settings: 'Settings',
          contact: 'Contact',
          contactUs: 'Contact Us',
          contactName: 'Your Name',
          contactEmail: 'Your Email',
          contactMessage: 'Your Message',
          contactSubmit: 'Submit',
          contactSuccess: 'Thank you for your message! We will get back to you when we can.',
          showFilters: 'Show Filters',
          hideFilters: 'Hide Filters',
          hideSettings: 'Hide Settings',
          refresh: 'Refresh',
          searchPlaceholder: 'Search the latest news...',
          selectAll: 'Select All',
          deselectAll: 'Deselect All',
          timeLimitLabel: 'Show posts from the past',
          timeLimitInfo: ' (hours) - Set to 0 to disable.',
          themeToggleLabel: 'Light/Dark:',
          languageLabel: 'Language:',
          feedsRefreshed: 'Videos Refreshed!',
          english: 'English',
          german: 'German',
          french: 'French',
          spanish: 'Spanish',
          timeAgo: {
         years: 'years ago',
         year: '1 year ago',
         months: 'months ago',
         month: '1 month ago',
         days: 'days ago',
         day: '1 day ago',
         hours: 'hours ago',
         hour: '1 hour ago',
         minutes: 'minutes ago',
         minute: '1 minute ago',
         seconds: 'seconds ago'
    },
        },
      },
    };
  },
  computed: {
    filteredVideos() {
      let videos = this.videos;

      // Apply sorting based on the selected sort option
      if (this.selectedSort === 'mostViewed') {
        // Sort by most viewed
        videos.sort((a, b) => parseInt(b.views.replace(/,/g, '')) - parseInt(a.views.replace(/,/g, '')));
      } else {
        // Default to sorting by most recent
        videos.sort((a, b) => new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime());
      }

      return videos;
    },
    filteredItems() {
      let items = this.rssItems;

      // Handle the case where there are no selected filters and no search query
      if (this.selectedFilters.length === 0 && this.searchQuery.trim() === '') {
        return []; // No items should be displayed if no filters or search are active
      }

      // Handle active search but no selected filters
      if (this.selectedFilters.length === 0 && this.searchQuery.trim() !== '') {
        return []; // No items should be displayed if no filters are selected
      }
      

      // First, filter by search query
      if (this.searchQuery.trim() !== '') {
        const query = this.searchQuery.toLowerCase();
        items = items.filter(
          (item) =>
            item.title.toLowerCase().includes(query) ||
            item.excerpt.toLowerCase().includes(query)
        );
      }

      // Now filter by selected sources
      if (this.selectedFilters.length > 0) {
        items = items.filter((item) => this.selectedFilters.includes(item.sourceName));
      }

      // Filter out items with "Monopoly GO" in the title
      items = items.filter((item) => !item.title.toLowerCase().includes('monopoly go'));

      // Filter by time limit if needed
      if (this.timeLimit > 0) {
        const currentTime = new Date();
        items = items.filter((item) => {
          const itemTime = new Date(item.pubDate);
          const timeDiff = (currentTime - itemTime) / 3600000; // Difference in hours
          return timeDiff <= this.timeLimit;
        });
      }

      // Exclude items with future publication dates (negative time differences)
      items = items.filter((item) => new Date(item.pubDate) <= new Date());

      // Apply pagination to limit the number of items shown
      return items.slice(0, this.currentPage * this.itemsPerPage);
    },

    refreshButtonText() {
      return this.isRefreshed ? this.translatedText.feedsRefreshed : this.translatedText.refresh;
    },
  },
  methods: {
    async fetchVideosFromJson() {
  this.videos = [];

  try {
    const response = await fetch('/ffe5yt4y5u5u.json');
    const data = await response.json();

    // Filter based on selected sources, but keep pubDate as a string
    this.videos = data
      .filter(video => this.selectedFilters.includes(video.sourceName))
      .sort((a, b) => new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime()); // Compare pubDate as it is

    // Assign sorted and filtered videos to the filteredVideos property
    this.filteredVideos = this.videos;
  } catch (error) {
    console.error('Error fetching videos:', error);
  }
},

    getFlag(language) {
    const flags = {
      en: 'https://flagcdn.com/w20/gb.png', // UK Flag for English
      de: 'https://flagcdn.com/w20/de.png', // Spain Flag for German
      fr: 'https://flagcdn.com/w20/fr.png', // France Flag for French
      es: 'https://flagcdn.com/w20/es.png', // Spanish Flag for Spanish
    };
    return flags[language] || flags['en'];
  },
  toggleSortOptions() {
    this.showSortOptions = !this.showSortOptions; // Toggle the dropdown visibility
  },
  sortBy(option) {
    this.selectedSort = option; // Update the selected sorting option
    this.fetchVideosFromJson(); // Fetch videos or apply sorting
    // Remove the line that closes the dropdown
  },
  openVideo(videoLink) {
    const videoId = new URL(videoLink).searchParams.get('v');
    
    // Detect if the user is on a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // Open video in a new tab if on a mobile device
      window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank');
    } else {
      // Open video in modal for desktop users
      this.activeVideo = videoId;
      this.showModal = true;
    }
  },

closeModal() {
  this.showModal = false;
  this.activeVideo = '';
},

  submitForm() {
    fetch("https://www.formbackend.com/f/6336af5133e6f762", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: this.name,
        email: this.email,
        message: this.message,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Something went wrong");
        }
        return response.json();
      })
      .then(() => {
        this.successMessage = "Thank you for your message!\nWe will get back to you when we can.";
        this.name = "";
        this.email = "";
        this.message = "";
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  },
    changeLanguage() {
    this.translatedText = this.languageOptions[this.selectedLanguage];
    this.fetchAndDisplayFeeds(); // Fetch and update feed items when language changes
  },
  refreshItems() {
      this.fetchAndDisplayFeeds(); // Call the method to refresh/reload items

      // Temporarily change the button text
      this.isRefreshed = true;
      setTimeout(() => {
        this.isRefreshed = false;
      }, 3000); // Reset the text after 3 seconds
    },
    handleImageLoad(index) {
      this.loadingImages[index] = true; // Mark the image as loaded
    },
    handleImageError(index) {
      this.loadingImages[index] = false; // Keep skeleton in case of error
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    toggleSettings() {
      this.showSettings = !this.showSettings;
    },
    saveTimeLimit() {
      localStorage.setItem(this.timeLimitStorageKey, this.timeLimit);
      this.currentPage = 1;
    },
closeChangelog() {
  this.showChangelog = false;
  localStorage.setItem('YouTubeChangelogDismissed', 'true');  // Updated key for changelog dismiss
  localStorage.setItem('YouTubeChangelogMessage', this.YouTubeChangelog);  // Updated key for changelog message
},
checkChangelogStatus() {
  const changelogDismissed = localStorage.getItem('YouTubeChangelogDismissed');  // Updated key for changelog dismiss
  const storedMessage = localStorage.getItem('YouTubeChangelogMessage');  // Updated key for changelog message

  if (!changelogDismissed || storedMessage !== this.YouTubeChangelog) {
    this.showChangelog = true;
  }
},
    getTranslatedText(item, field) {
    const languageKey =
      this.selectedLanguage === 'de'
        ? 'German'
        : this.selectedLanguage === 'fr'
        ? 'French'
        : this.selectedLanguage === 'es'
        ? 'Spanish'
        : '';
    if (languageKey) {
      return item[`${field}${languageKey}`] || item[field]; // Fallback to default field if translation doesn't exist
    }
    return item[field]; // Default to English if no languageKey is set
  },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    toggleTheme() {
      this.theme = this.isDarkTheme ? 'dark' : 'light';
      localStorage.setItem(this.themeStorageKey, this.theme);
    },
    formatPublishedDate(date) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  toggleFilter(sourceName) {
      const index = this.selectedFilters.indexOf(sourceName);
      if (index > -1) {
        this.selectedFilters.splice(index, 1); // Remove if already selected
      } else {
        this.selectedFilters.push(sourceName); // Add if not selected
      }
      this.fetchVideosFromJson(); // Fetch videos after updating filters
    },
    selectAllFilters() {
  this.selectedFilters = this.rssSources.map((source) => source.name); // Add all channels
  this.saveSelectedFilters(); // Save the selected filters in localStorage
  this.fetchVideosFromJson(); // Corrected: Fetch videos from JSON
},
  deselectAllFilters() {
    this.selectedFilters = []; // Clear all selected filters
    this.saveSelectedFilters(); // Save the empty filters array in localStorage
    this.fetchVideosFromJson(); // Fetch videos with no filters applied
  },
  saveSelectedFilters() {
    localStorage.setItem(this.storageKey, JSON.stringify(this.selectedFilters));
  },
  loadSelectedFilters() {
    const savedFilters = localStorage.getItem(this.storageKey);
    if (savedFilters) {
      // Load filters from localStorage if available
      this.selectedFilters = JSON.parse(savedFilters);
    } else {
      // Select all channels by default for new users
      this.selectedFilters = this.rssSources.map((source) => source.name);
    }

    // Load the time limit from localStorage
    const savedTimeLimit = localStorage.getItem(this.timeLimitStorageKey);
    if (savedTimeLimit !== null) {
      this.timeLimit = Number(savedTimeLimit);
    }

    // Load theme from localStorage
    const savedTheme = localStorage.getItem(this.themeStorageKey);
    if (savedTheme) {
      this.theme = savedTheme;
      this.isDarkTheme = savedTheme === 'dark';
    }

    // Load the selected language from localStorage
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      this.selectedLanguage = savedLanguage;
    }

    // Set the translated text based on the selected language
    this.changeLanguage();
  },
    fetchAndDisplayFeeds() {
    if (this.isLoading) return;

    this.isLoading = true;

    // Fetch the locally saved RSS feed data
    fetch('/3ht93htt3.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const allItems = data

          .map((item) => {
            return {
              title: this.getTranslatedText(item, 'title'),
              excerpt: this.getTranslatedText(item, 'excerpt'),
              link: item.link,
              pubDate: new Date(item.pubDate),
              sourceName: item.sourceName,
              image: item.image || this.placeholderImageUrl,
            };
          })
          .sort((a, b) => Date.parse(b.pubDate) - Date.parse(a.pubDate));

        this.rssItems = allItems;
        this.isLoading = false;
      })
      .catch((error) => {
        console.error('Error loading local RSS feeds:', error);
        this.isLoading = false;
      });
  },
    getSourceColor(sourceName) {
      const source = this.rssSources.find((s) => s.name === sourceName);
      return source ? source.color : '#000';
    },
    truncateText(text, limit = 150) {
  if (!text) return ''; // If text is undefined or null, return an empty string
  if (text.length <= limit) return text; // If text is shorter than the limit, return the whole text
  return text.substr(0, limit) + '...'; // Truncate the text and add ellipsis
},
    timeSince(date) {
  const currentTime = new Date();
  const seconds = Math.floor((currentTime - date) / 1000);
  const translatedTimeAgo = this.translatedText.timeAgo;

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) return `${interval} ${translatedTimeAgo.years}`;
  if (interval === 1) return `${translatedTimeAgo.year}`;

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) return `${interval} ${translatedTimeAgo.months}`;
  if (interval === 1) return `${translatedTimeAgo.month}`;

  interval = Math.floor(seconds / 86400);
  if (interval > 1) return `${interval} ${translatedTimeAgo.days}`;
  if (interval === 1) return `${translatedTimeAgo.day}`;

  interval = Math.floor(seconds / 3600);
  if (interval > 1) return `${interval} ${translatedTimeAgo.hours}`;
  if (interval === 1) return `${translatedTimeAgo.hour}`;

  interval = Math.floor(seconds / 60);
  if (interval > 1) return `${interval} ${translatedTimeAgo.minutes}`;
  if (interval === 1) return `${translatedTimeAgo.minute}`;

  return `${Math.floor(seconds)} ${translatedTimeAgo.seconds}`;
},
    isBrightColor(color) {
      const c = color.substring(1);
      const rgb = parseInt(c, 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      return brightness > 123;
    },
    handleScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      const threshold = document.documentElement.scrollHeight - 300;

      if (scrollPosition >= threshold && !this.isLoading) {
        this.currentPage++;
        this.fetchAndDisplayFeeds();
      }
    },
    decodeHtml(html) {
      const txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    },
  },
  mounted() {
    this.selectedFilters = this.rssSources.map(source => source.name);
    this.fetchVideosFromJson(); // Load videos on page load
    this.loadSelectedFilters(); // Load filters from localStorage
  this.$nextTick(() => {
    if (this.selectedFilters.length > 0) {
      this.fetchVideosFromJson(); // Ensure videos are fetched after filters are loaded
    }
  });
  this.checkChangelogStatus();

  // Sort RSS sources alphabetically
  this.rssSources.sort((a, b) => a.name.localeCompare(b.name));

  // Add scroll event listener for infinite scrolling
  window.addEventListener('scroll', this.handleScroll);

    // Preload images using IntersectionObserver (optional code for images)
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.src = img.dataset.src; // Set the real image source
            observer.unobserve(img); // Stop observing once the image is loaded
          }
        });
      },
      { rootMargin: '100px' } // Preload when the image is 100px away from the viewport
    );

    // Observe all the images that have a data-src attribute (lazy-load)
    this.$nextTick(() => {
      document.querySelectorAll('img[data-src]').forEach((img) => {
        observer.observe(img);
      });
    });
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    selectedLanguage(newLang) {
    this.changeLanguage(); // When the language changes, update the translations and fetch new items
    localStorage.setItem('selectedLanguage', newLang);
  },

  selectedFilters: {
    handler() {
      this.saveSelectedFilters(); // Save to localStorage on change
    },
    deep: true // Ensure Vue watches for changes in array elements
  }
  },
};
</script>
<style>
/* Import the Inter font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600;700&display=swap');


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  overscroll-behavior: none;
}

.language-selector {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-family: 'Inter', sans-serif;
}

.custom-select {
  position: relative;
  display: flex;
  align-items: center;
}

.light .custom-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 8px 10px;
  border-radius: 5px;
  border: 1px solid #333;
  font-family: 'Inter', sans-serif;
  background-color: #ffffff;
  color: #000000;
  width: 100px;
  font-size: 14px;
  cursor: pointer;
}

.filter-controls-wrapper {
  width: 100%; /* Ensure the controls take full width */
  margin-top: 10px; /* Add space between the filter buttons and filters */
  display: flex;
  flex-direction: column; /* Stack the buttons and filters vertically */
  gap: 10px; /* Add some space between buttons and filters */
  
}

.dark .custom-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 8px 10px;
  border-radius: 5px;
  border: 1px solid #333;
  font-family: 'Inter', sans-serif;
  background-color: #151719;
  color: #fff;
  width: 100px;
  font-size: 14px;
  cursor: pointer;
}

.custom-select select:focus {
  outline: none;
  border-color: #b541e9;
}

.custom-select .flag {
  position: absolute;
  right: 10px;
  pointer-events: none; /* Keep the flag non-interactive */
}

.flag-img {
  width: 20px;
  height: 14px;
  display: block;
}


.dark {
  background-color: #000;
  color: #fff;
}

.light {
  background-color: #f9f9f9;
  color: #000;
}

.container {
  width: 100%;
  min-height: 100vh;
}

.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.thank-you-message {
  text-align: center;
  color: #000; /* Set the text color to black */
}

.thank-you-message h3 {
  margin-top: 20px;
  font-size: 16px;
  color: #000; /* Ensures the thank you message is in black */
}


h6, h4 {
  padding-top: 20px;
  margin: 0;
  text-align: center;
}

.rss-filter {
  top: 0;
  z-index: 1000;
  background-color: #151719; /* Dark background to wrap around filter content */
  padding: 10px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap; /* Allow the filters and dropdown to stack */
  gap: 10px; /* Increased gap to avoid clutter */
  margin-bottom: 10px;
  margin-top: 25px;
  border-radius: 5px; /* Round corners for the filter background */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  border: 1px solid #393939;
}


.filter-toggle {
  padding: 7px 10px;
  border: none;
  border-radius: 5px;
  background-color: #151719;
  color: #fff;
  cursor: pointer;
  border: 1px solid #393939;
  font-size: 16px;
  margin-right: 5px; /* Add some space between filter and settings buttons */
}

.filter-toggle:hover {
  background-color: #454545;
}

.filter-control {
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
}

.rss-tag-filter {
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 15px; /* Rounded corners for wrapping effect */
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  border: 1px solid #333;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  background-color: transparent; /* Transparent background by default */
}

.video-stats {
  margin-top: 10px;
  font-size: 14px;
  color: #888;
}
.video-stats span {
  margin-right: 10px;
}


.contact-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contact-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darken background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contact-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.contact-modal h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: black;
}

.contact-modal label {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
}

.contact-modal input,
.contact-modal textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
}

.contact-modal textarea {
  height: 100px;
  resize: none;
}

.submit-btn {
  width: 100%;
  padding: 10px;
  background-color: #8e44ad;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #7a39a0;
}

.close-modal {
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
}

.thank-you-message {
  text-align: center;
}


.light .rss-tag-filter {
  background-color: #f0f0f0; /* Light background for light mode */
  color: #000; /* Text color for light mode */
  border-color: #ccc; /* Adjust border for light mode */
}

/* Styles for dark theme */
.dark .rss-tag-filter {
  background-color: #151719; /* Dark background for dark mode */
  color: #fff; /* Text color for dark mode */
  border-color: #333; /* Adjust border for dark mode */
}

.light .rss-filter {
  background-color: #F9F9F9; /* Light background for light mode */
  color: #000; /* Text color for light mode */
  border-color: #ccc; /* Adjust border for light mode */
}

.light .filter-toggle {
  background-color: #F9F9F9; /* Light background for light mode */
  color: #000; /* Text color for light mode */
  border-color: #ccc; /* Adjust border for light mode */
}

.dark .filter-toggle {
  background-color: #151719; /* Dark background for dark mode */
  color: #fff; /* Text color for dark mode */
  border-color: #333; /* Adjust border for dark mode */
}

.light .search-bar {
  background-color: #F9F9F9; /* Light background for light mode */
  color: #000; /* Text color for light mode */
  border-color: #ccc; /* Adjust border for light mode */
}

.dark .search-bar {
  background-color: #151719; /* Dark background for dark mode */
  color: #fff; /* Text color for dark mode */
  border-color: #333; /* Adjust border for dark mode */
}

.light .settings-dropdown input {
  background-color: #F9F9F9; /* Light background for light mode */
  color: #000; /* Text color for light mode */
  border-color: #ccc; /* Adjust border for light mode */
}

.dark .settings-dropdown input {
  background-color: #151719; /* Dark background for dark mode */
  color: #fff; /* Text color for dark mode */
  border-color: #333; /* Adjust border for dark mode */
}

.dark .rss-item {
  background-color: #151719;
}

.light .rss-item {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
}

.light .filter-control {
  background-color: #F9F9F9; /* Light background for light mode */
  color: #000; /* Text color for light mode */
  border-color: #ccc; /* Adjust border for light mode */
  border: 1px solid #333;
}

.dark .filter-control {
  background-color: #151719; /* Dark background for dark mode */
  color: #fff; /* Text color for dark mode */
  border: 1px solid #333;
}

.rss-tag-filter.selected {
  border-color: #151719;
}

.rss-tag-filter:hover {
  border-color: #555;
}

.settings-dropdown {
  width: 100%; /* Make the settings dropdown full width */
  padding: 10px;
  border-radius: 5px;
  margin-top: -5px;
  margin-bottom: -25px;
}

/* Dropdown for Sort By options */
.sort-options-dropdown {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
  background-color: #151719; /* Match theme */
}

.sort-option {
  padding: 10px;
  background-color: #333;
  color: white;
  margin-left: 5px;
  border: none;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sort-option:hover {
  background-color: #444;
}

.sort-option.selected {
  background-color: #B541E9; /* Highlight selected option */
}


.sort-option:focus {
  outline: none;
}

.sort-option.selected {
  background-color: #B541E9; /* Highlight selected option */
}


.settings-dropdown label {
  margin-right: 10px;
}

.homepage-link {
  text-decoration: none; /* Remove the underline */
  color: inherit; /* Inherit the text color from the parent */
}

.homepage-link:hover {
  text-decoration: underline; /* Optional: Add an underline on hover if desired */
}

.settings-dropdown input {
  width: 40px;
  padding: 5px;
  border: 1px solid #555;
  border-radius: 3px;
  margin-left: -5px;
  background-color: #151719;
  color: #fff;
}

.settings-dropdown button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #444;
  color: #fff;
  cursor: pointer;
}

/* Toggle Switch Styles */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px; /* Width of the toggle */
  height: 24px; /* Height of the toggle */
  margin-left: 10px; /* Space between label and toggle */
}

/* Style the sort buttons */
.sort-options {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.sort-option {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sort-option:hover {
  background-color: #444;
}

.sort-option:focus {
  outline: none;
}

.sort-option.selected {
  background-color: #B541E9;
  color: white;
}


h1 {
  font-size: 2.5rem; /* Adjust font size */
  text-transform: uppercase; /* Make the text uppercase */
  letter-spacing: 2px; /* Adjust letter spacing */
  color: #8e44ad; /* Base color */
  background: linear-gradient(to right, #8e44ad, #e056fd); /* Subtle purple to pink gradient */
  -webkit-background-clip: text; /* Clip the background to text */
  -webkit-text-fill-color: transparent; /* Make the fill color transparent */
  margin: 0;
  text-align: center;
  padding: 5px 20px; /* Add padding for spacing */
  border-radius: 5px; /* Slightly round the corners */
}


.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: -14px;
  left: -15px;
  right: 4px;
  bottom: 14px;
  background-color: #ccc; /* Default background color */
  transition: 0.4s; /* Smooth transition */
  border-radius: 24px; /* Rounded corners for the slider */
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px; /* Height of the circle */
  width: 20px; /* Width of the circle */
  left: 2px; /* Spacing inside the toggle switch */
  bottom: 2px;
  background-color: white; /* Color of the circle */
  transition: 0.4s; /* Smooth transition */
  border-radius: 50%; /* Make the circle rounded */
}

input:checked + .toggle-slider {
  background-color: #B541E9; /* Background color when checked */
}

input:checked + .toggle-slider:before {
  transform: translateX(26px); /* Move the circle to the right when checked */
}

.rss-feed {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding-bottom: 60px;
}

.rss-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out content */
  border: 1px solid #393939;
  padding: 10px;
  border-radius: 5px;
  background: #151719;
  text-decoration: none;
  transition: border-color 0.3s;
  overflow: hidden;
  height: 375px;
}

.rss-item .rss-content {
  flex-grow: 1; /* Allow the content to grow */
}

.rss-item .rss-footer {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.rss-item.dark {
  background-color: #151719;
}

.rss-item.light {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
}

.rss-item:hover {
  border-color: #B541E9;
}

.rss-item img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  max-height: 160px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}

.rss-item h2 {
  font-size: 18px;
  margin: 5px 0;
  margin-bottom: 15px;
}

.theme-toggle {
  margin-top: 10px;
}

.close-modal-button {
  position: absolute;
  top: 10px; /* Position at the top of the modal, outside the video box */
  right: 10px;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #8e44ad;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000; /* Ensure it stays on top of the video */
}

.close-modal-button:hover {
  background-color: #7a39a0;
}

.rss-item p {
  font-size: 13px;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: block;
  margin-bottom: 10px; /* Add space between content */
  flex-grow: 1; /* Allow content to grow and push the meta section down */
}

/* Separator styling */
.separator {
  margin: 2px 5px; /* Space between tag and time */
}

.rss-item time {
  font-size: 12px;
  color: #999;
  display: inline-block; /* Inline-block to ensure proper flex alignment */
  margin-top: 1px;
}

.rss-tag {
  display: inline-block;
  padding: 2px 10px;
  font-size: 12px;
  color: #fff;
  border-radius: 3px;
  margin: 0; /* Remove unnecessary margins */
}

.loading {
  text-align: center;
  padding: 10px;
  color: #666;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #999;
}
  
  .toggle-slider {
  position: absolute;
  cursor: pointer;
  top: -14px;
  left: -15px;
  right: 4px;
  bottom: 14px;
  background-color: #ccc; /* Default background color */
  transition: 0.4s; /* Smooth transition */
  border-radius: 24px; /* Rounded corners for the slider */
}

.rss-item.dark {
  background-color: #151719;
}

.rss-item.light {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
}

.refresh-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #333;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  opacity: 0.9;
  transition: opacity 0.5s ease-in-out;
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}


#load-more-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.scroll-up-btn {
  all: unset;
  position: fixed;
  bottom: 25px;
  left: 30px;
  height: 50px;
  width: 50px;
  background-color: #F9F9F9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  z-index: 9999; /* Set a high z-index to keep the button on top */
}

.scroll-up-btn:hover {
  background-color: #e0e0e0; /* Change color on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.scroll-up-btn:active {
  background-color: #d0d0d0; /* Change color when button is clicked */
}

.scroll-up-btn .arrow-up {
  width: 15px;
  height: 15px;
  border-left: 4px solid #000000;
  border-bottom: 4px solid #000000;
  transform: rotate(135deg);
  position: relative;
  top: 5px; /* Adjust this value to fine-tune the vertical position */
  pointer-events: none;
}

.image-wrapper {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  width: 100%;
  height: 160px; /* Ensure the height is fixed or set accordingly */
}

.skeleton-loader {
  width: 100%;
  height: 100%;
  background-color: #dcdcdc; /* Less bright background */
  animation: pulse 1.5s ease-in-out infinite;
  border-radius: 4px;
}

.loading-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-left-color: #333;
  animation: spin 1s linear infinite;
  transform: translate(-50%, -50%); /* Ensures it is centered */
}

@keyframes pulse {
  0% {
    background-color: #151719;
  }
  50% {
    background-color: #151719;
  }
  100% {
    background-color: #151719;
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}


.rss-image {
  max-width: 100%;
  max-height: 160px;
  object-fit: cover;
  border-radius: 4px;
  display: block;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure it's on top of other content */
}

.modal-content {
  position: relative;
  width: 80%;
  max-width: 900px;
  background-color: #000;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
}

iframe {
  width: 100%;
  height: 500px; /* Adjust height as needed */
  border: none;
}


.search-bar {
  margin-left: auto; /* This keeps the search bar pushed to the far right */
  padding: 9px 10px;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #151719;
  color: #fff;
  font-size: 14px;
  width: auto; /* Allow the search bar to resize based on available space */
  flex-grow: 1; /* Allow the search bar to expand and fill available space */
  max-width: 100%; /* Ensure it doesn't overflow */
}

.changelog-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #f8f9fa;
  border: 1px solid #333;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 300px;
  color: #000;
  font-size: 14px;
}

.close-changelog {
  background: none;
  border: none;
  color: #333;
  font-weight: bold;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {

  iframe {
    width: 100vw; /* Full width of the viewport */
    height: 100vh; /* Full height of the viewport */
  }

  .modal-content {
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    max-width: none; /* Remove the max-width constraint */
    border-radius: 0; /* Remove border-radius for full-screen effect */
    padding: 0; /* Remove any padding */
  }

  .close-modal-button {
    top: 5px; /* Remove top positioning */
    right: auto;
    left: 50%; /* Center horizontally */
    transform: translateX(-50%);
    width: 80%; /* Make it wider on mobile */
  }

  .video-wrapper {
    aspect-ratio: 16 / 9;
    width: 100%;
  }

  .modal-overlay {
    padding: 0;
  }

  .scroll-up-btn {
  position: fixed;
  bottom: 25px;
  left: 30px;
  height: 50px;
  width: 50px;
  background-color: #F9F9F9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  z-index: 999; /* Make sure the scroll-up button is above everything else */
}

  .image-wrapper {
  position: relative;
  width: 100%;
  height: 85px; /* Ensure the height is fixed */
}

  .settings-dropdown {
    padding: 5px;
  }

  .rss-filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    width: 94%;
    gap: 10px;
  }

  /* Adjust rss-feed to show only one feed at a time */
  .rss-feed {
    display: flex; /* Use flex to ensure items take full height */
    flex-direction: column;
    overflow: hidden; /* Prevent horizontal scrolling */
    width: 100%; /* Ensure full width */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
  }

  .rss-item h2 {
  font-size: 18px;
  margin: 5px 0;
  margin-bottom: 15px;
}

  .rss-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out content */
  border: 1px solid #393939;
  padding: 10px;
  height: 400px;
  border-radius: 5px;
  background: #151719;
  text-decoration: none;
  transition: border-color 0.3s;
  overflow: hidden;
}

.rss-item p {
  font-size: 13px;
  margin-top: 125px; /* Increase the margin to move the text further down */
  margin-bottom: 20px; /* Increase space below the paragraph if needed */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: block;
  padding: 10px 0; /* Optional: Add some vertical padding */
  flex-grow: 1; /* Allow content to grow and push the meta section down */
}

  /* Adjust image to fit within the screen */
  .rss-item img {
  width: 100%; /* Make sure the image takes the full width of the container */
  height: 250px; /* Keeps the aspect ratio */
  max-height: 250px; /* Increase the maximum height to make the image larger */
  object-fit: contain; /* Ensures the image covers the available space without distortion */
  margin-bottom: 15px; /* Adjust spacing below the image */
  border-radius: 5px; /* Optional: keep some rounded corners */
  margin-top: 0px;
}


  /* Adjust the filter and settings controls */
  .filter-toggle,
  .filter-control {
    width: 100%; /* Make buttons adapt to the width */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  /* Adjust tag filter styling */
  .rss-tag-filter {
    width: 93%; /* Allow flexibility */
    margin-bottom: 7px;
    margin-right: 13px;
  }

  .changelog-box {
    width: 200px;
    font-size: 12px;
    bottom: 25px;
    right: 10px;
    padding: 10px;
  }

  .theme-toggle {
  margin-top: 20px;
  margin-bottom: 10px;
}
  
  .toggle-slider {
  position: absolute;
  cursor: pointer;
  top: -5px;
  left: -15px;
  right: 4px;
  bottom: 5px;
  background-color: #ccc; /* Default background color */
  transition: 0.4s; /* Smooth transition */
  border-radius: 24px; /* Rounded corners for the slider */
}

  /* Adjust other necessary elements */
  .content {
    padding: 10px; /* Adjust padding for mobile */
    width: 100vw; /* Ensure it fills the viewport width */
    box-sizing: border-box; /* Include padding in the width calculation */
  }

  .search-bar {
    width: 94%; /* Make the search bar full width on mobile */
    margin-bottom: 7px;
    margin-right: 13px;
  }
}


</style>
