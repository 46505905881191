<template>
  <div id="app">
    <!-- Header Section -->
    <header class="app-header">
      <nav class="app-nav">
        <div class="nav-links" :class="{ open: showDropdown }">
          <!-- Links to navigate between the pages -->
          <router-link
            to="/"
            class="nav-link"
            active-class="active-link"
            @click="closeDropdown"
          >News Feed</router-link>
          <router-link
            to="/youtube"
            class="nav-link"
            active-class="active-link"
            @click="closeDropdown"
          >YouTube Feed</router-link>
          <router-link
            to="/patch-notes"
            class="nav-link"
            active-class="active-link"
            @click="closeDropdown"
          >Patch Notes</router-link>
        </div>


        <!-- Mobile menu toggle button -->
        <button class="menu-toggle" @click="toggleDropdown">☰</button>
      </nav>
    </header>

    <!-- Router view for displaying the current component -->
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDropdown: false, // Track mobile menu state
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    closeDropdown() {
      this.showDropdown = false; // Close dropdown after a link is clicked
    },
  },
};
</script>

<style scoped>
/* Styling for header */
.app-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000; /* Black background */
  padding: 1rem;
  position: relative;
  z-index: 10;
}

/* Styling for navigation */
.app-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-link {
  color: #ecf0f1;
  text-decoration: none;
  padding: 0.8rem 1.2rem;
  border-radius: 5px;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #BF4FDC;
}

.active-link {
  color: #BF4FDC;
}

/* Mobile Styles */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: #ecf0f1;
  font-size: 2rem;
  cursor: pointer;
  position: relative; /* Make the toggle button relative */
  z-index: 20; /* Ensure it appears above the dropdown */
}

/* Mobile dropdown style */
.nav-links.open {
  display: block;
  position: absolute;
  top: 100%; /* Position the dropdown below the toggle button */
  left: 50%;
  transform: translateX(-50%); /* Center the dropdown under the button */
  width: 100vw; /* Take the full screen width */
  background-color: rgba(0, 0, 0, 0.9); /* Slightly transparent background */
  padding: 1rem 0;
  text-align: center;
  z-index: 10;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Soft shadow for depth */
  transition: all 0.3s ease;
}

.nav-links.open .nav-link {
  display: block;
  width: 100%;
  padding: 1.2rem 0; /* Increase padding for larger tappable area */
  border-bottom: 1px solid #333; /* Subtle border between items */
}

.nav-links.open .nav-link:last-child {
  border-bottom: none; /* Remove border for the last link */
}

/* Mobile-specific styling */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide links by default on mobile */
    flex-direction: column;
  }

  .menu-toggle {
    display: block; /* Show menu toggle button on mobile */
  }

  .nav-links.open {
    display: flex; /* Show the dropdown when open */
    flex-direction: column; /* Stack links vertically */
    align-items: center; /* Center links */
  }
}
</style>
