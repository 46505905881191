import { createRouter, createWebHistory } from 'vue-router';
import NewsFeed from '../src/components/NewsFeed.vue';
import YouTubeFeed from '../src/components/YouTubeFeed.vue';
import PatchNotes from '../src/components/PatchNotes.vue';

const routes = [
  { path: '/', component: NewsFeed },
  { path: '/youtube', component: YouTubeFeed },
  { path: '/patch-notes', component: PatchNotes },
  // Catch-all route for undefined paths
  { path: '/:pathMatch(.*)*', redirect: '/' }, // Redirect to homepage for any undefined route
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
